<script setup>
import useErrorConfig from '@/utils/errorConfig'

definePageMeta({ layout: null })
const route = useRoute()
const error = useErrorConfig().get(route.params.code)
if (!error)
  navigateTo('/error/404')
</script>

<template>
  <BaseError
    :image-src="`/images/error/${error.code}.png`"
    :title="`Error ${error.code} - ${error.title}`"
    :message="error.message"
    :text-button="error?.textButton || 'Kembali'"
  />
</template>
